import "./App.css";
import { BrowserRouter as Router} from "react-router-dom";
import AnimatedRoutes from "./components/AnimatedRoutes"
import Navbar from "./components/Navbar";
import React  from 'react';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <AnimatedRoutes/>

   
      </Router>
    </div>
  );
}

export default App;
