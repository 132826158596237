import React from 'react'
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Home from "../Pages/Home";

import ErrorPage from "../Pages/ErrorPage";

import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() {
    const location = useLocation();
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes