import React from 'react'
import {  Link } from "react-router-dom";
import "./Navbar.css";
import logo from '../assets/img/LOGO-BS.png'

const Navbar = () => {
  return (
    <div className="Navbar">
      <div className="leftSide">
          <img src={logo} className="logo-img" alt="Logo BKR Studio" />
      </div>
      <div className="rightSide">
        <div className="links">
          <div className="nav">

            <a className="menu-item btn-rounded" href="mailto:contact@blackcreststudio.com">Get in touch</a>

          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Navbar