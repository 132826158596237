import React from "react";
import {motion} from 'framer-motion'
import "./ErrorPage.css";

function ErrorPage() {
  return <motion.div 
  className="error"
  initial={{opacity:0}}
  animate={{opacity:1}}
  exit={{opacity:0}}
  > ERROR! PAGE NOT FOUND</motion.div>;
}

export default ErrorPage;
