import React from "react";
import {motion} from 'framer-motion'
import "./Home.css";
import arrowDown from '../assets/img/down-arrow.svg';


const hoursMinSecs = {hours:1, minutes: 20, seconds: 40}

function Home() {
  return (
  <motion.div 
  className="home"
  initial={{opacity:0}}
  animate={{opacity:1}}
  exit={{opacity:0}}
  transition={{ ease: "easeInOut"}}
  > 
   <div className="home-header">
      <div className="top-area">
          <h1>Digital Creative Studio with a focus on bespoke app development</h1>
    
      </div>
      <div className="bottom-area">

        <div className="section">
          <h3>FRONT-END DEV</h3>
          <p>WEB APPLICATIONS</p>
          <p>CORPORATE WEBSITE</p>
          <p>PORTFOLIO WEBSITE</p>
         
        </div>
        
        <div className="section">
          <h3>BACK-END DEV</h3>
          <p>APP & DATA INTEGRATION</p>
          <p>PROCESS OPTIMISATION</p>
          <p>API IMPLEMENTATION</p>
        </div>

        <div className="section section-end">
          <h3>OTHER</h3>
          <p>UI DESIGN</p>
          <p>GRAPHIC &  MOTION DESIGN</p>


        </div>
         
      </div>
    
   </div>

   
  </motion.div>
  )
}

export default Home;
